export const COOKIES = {
    Kmx_LP: 'Kmx_LP',
    Kmx_LQ: 'Kmx_LQ',
    visitor: 'KmxVisitor_0',
};

export function clearCookies() {
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()}`);
    });
    window.localStorage.clear();
}

export const getCookie = (cookieName: string) => {
    if (!cookieName) {
        return '';
    }
    var name = cookieName + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const isAuthenticated = (): boolean => {
    try {
        const authCookie = window.location.hostname === 'www.carmax.com' ? COOKIES.Kmx_LP: COOKIES.Kmx_LQ;
        return !!document.cookie.match(authCookie);
    } catch (e) {
        return false;
    }
};

export const getUserId = (): string => {
    try {
        const authCookie = window.location.hostname === 'www.carmax.com' ? COOKIES.Kmx_LP: COOKIES.Kmx_LQ;
        const cookieString: string = getCookie(authCookie);

        if(cookieString == undefined || cookieString === "") return "";

        const cookieSegments = cookieString.split("==");
        const decodedString = atob(cookieSegments[0]);

        const cookieObject = JSON.parse(decodedString);
        return cookieObject.CiamId;
    } catch {
        return '';
    }
};
