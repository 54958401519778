interface IOptions {
    url: string;
    apiKey?: string;
    withCredentials?: boolean;
    body?: string;
}
export type Options = IOptions;

export const get = (options: Options, success: (res: any) => any, error: (error: any) => any) => {
    if (success != null && typeof success === 'function' && error && typeof error === 'function') {
        try {
            const { url, apiKey, withCredentials } = options;
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.setRequestHeader('Accept', 'application/json');
            if (withCredentials) {
                xhr.withCredentials = withCredentials;
            }
            if (apiKey) {
                xhr.setRequestHeader('ApiKey', apiKey);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 && xhr.response) {
                        const response: any = JSON.parse(xhr.response);
                        success(response);
                    } else {
                        error(xhr.response);
                    }
                }
            };
            xhr.send();
        } catch (e) {
            error(e);
        }
    }
};
export const post = (options: Options, success: (res: any) => any, error: (error: any) => any) => {
    if (success != null && typeof success === 'function' && error && typeof error === 'function') {
        try {
            const { url, apiKey, withCredentials, body } = options;
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', 'application/json');
            if (withCredentials) {
                xhr.withCredentials = withCredentials;
            }
            if (apiKey) {
                xhr.setRequestHeader('ApiKey', apiKey);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (xhr.response) {
                            const response: any = JSON.parse(xhr.response);
                            success(response);
                        } else {
                            success({});
                        }
                    } else {
                        error(xhr.response);
                    }
                }
            };
            xhr.send(body);
        } catch (e) {
            error(e);
        }
    }
};
export const remove = (options: Options, success: (res: any) => any, error: (error: any) => any) => {
    if (success != null && typeof success === 'function' && error && typeof error === 'function') {
        try {
            const { url, apiKey, withCredentials, body } = options;
            const xhr = new XMLHttpRequest();
            xhr.open('DELETE', url);
            xhr.setRequestHeader('Accept', 'application/json');
            if (withCredentials) {
                xhr.withCredentials = withCredentials;
            }
            if (apiKey) {
                xhr.setRequestHeader('ApiKey', apiKey);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (xhr.response) {
                            const response: any = JSON.parse(xhr.response);
                            success(response);
                        } else {
                            success({});
                        }
                    } else {
                        error(xhr.response);
                    }
                }
            };
            xhr.send(body);
        } catch (e) {
            console.error(e);
        }
    }
};
