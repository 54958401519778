import { addSavedVehicles } from './api/savedVehicle';
import { FAVORITE_EVENT_NAMES, WATCHLIST_EVENT_NAMES } from './constants';
import { getUserId, isAuthenticated } from './utils/cookieHelper';
import { deleteWatchlist, getStockNumbers, removeAuthRedirectUrl } from './utils/watchlistHelper';
// tslint:disable: no-unused-expression
let watchlistEnabled = true;
export const init = () => {
    window.addEventListener(FAVORITE_EVENT_NAMES.SAVE_FAVORITES, event => {
        const customEvent = event as CustomEvent;
        const result = saveAuthenticatedFavorites();
        customEvent.detail.callback(result) as CustomEvent;
    });
    window.addEventListener(WATCHLIST_EVENT_NAMES.WATCHLIST_ENABLED, () => {
        watchlistEnabled = true;
    });
};

const saveAuthenticatedFavorites = async (): Promise<boolean> => {
    if (!isAuthenticated()) {
        return new Promise(resolve => {
            resolve(false);
        });
    }
    const userId = getUserId();
    return new Promise((resolve, reject) => {
        if (userId === '') resolve(false);
        const stockNumbers = getStockNumbers();
        if (!stockNumbers || stockNumbers.length < 1) resolve(true);
        try {
            addSavedVehicles(userId, stockNumbers, (response: any) => {
                if (response) {
                    deleteWatchlist();
                    removeAuthRedirectUrl();
                    resolve(true);
                } else resolve(false);
            });
        } catch (e) {
            console.error(`Error saving local cars: ${e}`);
            reject(false);
        }
    });
};
init();
// tslint:enable: no-unused-expression
