import * as environment from '../utils/environment';
import { get, Options, post, remove } from './apiHelper';

export const getUrl = (userId: string, stockNumber?: number) => {
    const domain =
        environment.get().dev || environment.get().qa
            ? 'https://wwwqa.carmax.com/savedvehicles'
            : 'https://www.carmax.com/savedvehicles';
    return `${domain}/saved-cars/${userId}/${stockNumber ? stockNumber : ''}`;
};
export const postClickEvent = (stockNumber: number, adding: boolean) => {
    let cookiebits = document.cookie.split('; ').reduce(function(prev: any, current) {
        var name = current.split(/=(.+)/)[0];
        var value = current.split(/=(.+)/)[1];
        prev[name] = value;
        return prev;
    }, {});
    let kmxVisitor = cookiebits['KmxVisitor_0'].split('&').reduce(function(prev: any, current: any) {
        var name = current.split(/=(.+)/)[0];
        var value = current.split(/=(.+)/)[1];
        prev[name] = value;
        return prev;
    }, {});
    let target = 'click';
    let elementType = adding ? 'Car Saved' : 'Car Removed';
    let clickEvent = {
        url: window.location.href,
        language: navigator.language,
        userAgent: navigator.userAgent,
        clickTime: new Date().toUTCString(),
        customProperties: {
            clickedElement: {
                elementType: elementType,
            },
            source: 'saved-vehicle-assets',
            'add-saved-vehicle': adding,
            stockNumber: stockNumber,
        },
        sessionId: kmxVisitor['VisitorID'],
        eventData: cookiebits,
        element: 'favorite-car-button',
        recentlyViewed: window.localStorage.getItem('viewedVehicles'),
        eventType: target,
    };
    let xhr = new XMLHttpRequest();
    if (window.location.href.indexOf('www.') === -1) {
        xhr.open('POST', 'https://stagingqa.carmax.com/api/click', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-functions-key', 'SK5ZHxhcM/wYzWHl4fhH94jjlaKuRQPjUrWEdNgkGQ6FE0etG/hEYA==');
    } else {
        xhr.open('POST', 'https://www.carmax.com/api/click', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('x-functions-key', '1k4XotOfjkHjauICyRXD7VIvDsQwF5ka0wDnHEZucdXvMs1dC9jlgQ==');
    }
    xhr.send(
        JSON.stringify({
            value: clickEvent,
        })
    );
};
export const addSavedVehicle = (userId: string, stockNumber: number, done: any) => {
    const uri = getUrl(userId, stockNumber);
    const request = {
        sendNotifications: false,
    };
    const options: Options = {
        body: JSON.stringify(request),
        url: uri,
    };
    post(
        options,
        () => {
            done();
        },
        error => {
            console.error(error);
            done(false);
        }
    );
    postClickEvent(stockNumber, true);
};
export const addSavedVehicles = (userId: string, stockNumbers: number[], done: any) => {
    const uri = getUrl(userId);
    const request = { StockNumbers: stockNumbers, SendNotifications: false };
    const options: Options = {
        body: JSON.stringify(request),
        url: uri,
    };
    post(
        options,
        res => {
            done(res);
        },
        function error(e) {
            console.error('Error adding saved vehicle: ', e);
            done(false);
        }
    );
    stockNumbers.map(s => postClickEvent(s, true));
};
export const removeSavedVehicle = (userId: string, stockNumber: number, done: any) => {
    const uri = getUrl(userId, stockNumber);
    const options: Options = {
        url: uri,
    };
    remove(
        options,
        () => {
            done();
        },
        function error(e) {
            console.error('Error adding saved vehicle: ', e);
            done(false);
        }
    );
    postClickEvent(stockNumber, false);
};
export const getSavedVehicles = async (userId: string, done: any) => {
    const uri = getUrl(userId);
    const options: Options = {
        url: uri,
    };
    get(
        options,
        res => {
            done(res);
        },
        function error(e) {
            console.error('Error adding saved vehicle: ', e);
            done(false);
        }
    );
};
