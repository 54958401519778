// tslint:disable-next-line: no-submodule-imports
import { KMXDialog } from '@kmx/legos-dialog/index';
import img from '../static/overview.svg';
import { registerUrl, signInUrl } from '../utils/watchlistHelper';

export interface IOverviewDialogue {
    render(stockNumber?: string): void;
}

export default class OverviewDialogue implements IOverviewDialogue {
    public render(stockNumber?: string): void {
        const bodyEl = document.getElementsByTagName('body')[0];
        const html = `<aside id="watchlist-overview"
                class="kmx-dialog watchlist-overview"
                role="alertdialog"
                aria-describedby="watchlist-overview-description"
                aria-labelledby="overview-dialogue-title">
                <div class="mdc-dialog__surface" id="watchlist-overview-dialog-container" aria-labelledby="overview-dialogue-title" tabIndex="-1">
                    <div class="watchlist-overview-upper">
                        ${img}
                    </div>
                    <div class="watchlist-overview-lower">
                        <header class="mdc-dialog__header">
                            <h2 class="mdc-dialog__header__title" id="overview-dialogue-title">Great choice</h2>
                            <button id="btn-watchlist-overview-close" class="button-dialog-close" aria-label="Close dialog">
                                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" role="presentation" width="24" height="24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                            </button>
                        </header>
                        <section id="watchlist-overview-description" class="mdc-dialog__body">
                        <span class="watchlist-overview-inline-heart">
                        <p>   <svg viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg" aria-labelledby="save">
                                <title>save</title>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
                            </svg>
                            </span> up to 3 more with no account</p>
                            <div class="">
                            <button type="button" class='watchlist-button got-it-button kmx-button kmx-button--primary'>Ok, Got It</button>
                            </div>
                            <p class="pl-createaccount"><a class="pl-register" href="${registerUrl(stockNumber)}">Create account</a></p>
                            <div><p class="pl-note">Already have an account? <a class="pl-signin" href="${signInUrl(stockNumber)}">Sign in</a> </p></div>
                        </section>
                    </div>
                </div>
                <div class="mdc-dialog__backdrop"></div>
            </aside>`;

        bodyEl.insertAdjacentHTML('beforeend', html);

        const dialogElement = document.querySelector('#watchlist-overview');
        const dialog = new KMXDialog(dialogElement);
        const button = document.querySelector('.got-it-button') as HTMLElement;
        if (button !== null) {
            button.onclick = () => {
                closeDialog();
            };
        }

        const closeButton = document.getElementById('btn-watchlist-overview-close') as HTMLElement;
        if (closeButton !== null) {
            closeButton.onclick = () => {
                closeDialog();
            };
        }

        const closeDialog = () => {
            if (dialog && dialog !== null) {
                dialog.close();
            }
        };

        dialog.open();

        const container = document.getElementById('watchlist-overview-dialog-container');
        if (container) {
            container.focus();
        }
    }
}
