import '../scss/index.scss';
import * as manageFavorites from '../ts/manageFavorites';
import * as manageWatchlistConversion from '../ts/manageWatchlistConversion';
import { loadSnacks } from './notification';

document.addEventListener('DOMContentLoaded', () => {
    loadSnacks();
});

manageFavorites.init();
manageWatchlistConversion.init();
