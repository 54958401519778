import * as constants from '../constants';
import * as environment from '../utils/environment';
export const addVehicle = (stockNumber: number) => {
    const storedValue = localStorage.getItem(constants.WATCHLIST_LOCAL_STORAGE);
    const watchList = storedValue ? JSON.parse(storedValue) : [];

    if (watchList.length > 0) {
        const existingVehicleIndex = watchList
            .map((vehicle: any) => {
                return vehicle.stockNumber;
            })
            .indexOf(stockNumber);

        if (existingVehicleIndex !== -1) {
            return;
        }
    }

    watchList.unshift({
        stockNumber,
        timestamp: Date.now(),
    });

    localStorage.setItem(constants.WATCHLIST_LOCAL_STORAGE, JSON.stringify(watchList));
};

export const removeVehicle = (stockNumber: number) => {
    const storedValue = localStorage.getItem(constants.WATCHLIST_LOCAL_STORAGE);
    const watchList = storedValue ? JSON.parse(storedValue) : [];
    if (watchList.length > 0) {
        const existingVehicleIndex = watchList
            .map((vehicle: any) => {
                return vehicle.stockNumber as number;
            })
            .indexOf(stockNumber);
        if (existingVehicleIndex !== -1) {
            watchList.splice(existingVehicleIndex, 1);
            localStorage.setItem(constants.WATCHLIST_LOCAL_STORAGE, JSON.stringify(watchList));
        }
    }
};

export const deleteWatchlist = () => {
    localStorage.removeItem(constants.WATCHLIST_LOCAL_STORAGE);
};
export const getStockNumbers = () => {
    const storedValue = localStorage.getItem(constants.WATCHLIST_LOCAL_STORAGE);
    const watchList = storedValue ? JSON.parse(storedValue) : [];

    return watchList.map((vehicle: any) => {
        return vehicle.stockNumber;
    });
};

export const overViewWasDisplayed = () => {
    const wasDisplayed = localStorage.getItem(constants.WATCHLIST_OVERVIEW_WAS_DISPLAYED_LOCAL_STORAGE);
    return wasDisplayed ? true : false;
};

export const setOverViewWasDisplayed = () => {
    localStorage.setItem(constants.WATCHLIST_OVERVIEW_WAS_DISPLAYED_LOCAL_STORAGE, 'true');
};
export const setAuthRedirectUrl = () => {
    const url =
        environment.get().dev || environment.get().qa
            ? `https://wwwqa.carmax.com${constants.links.postAuthRedirect.url}`
            : `https://www.carmax.com${constants.links.postAuthRedirect.url}`;
    localStorage.setItem(constants.links.postAuthRedirect.name, url);
};
export const removeAuthRedirectUrl = () => {
    localStorage.removeItem(constants.links.postAuthRedirect.name);
};
export const getAuthRedirectUrl = (): string => {
    const url = localStorage.getItem(constants.links.postAuthRedirect.name);
    return url ? url : '';
};
export function stockNumbersToQueryString(stockNumbers: number[]): string {
    // tslint:disable-next-line: strict-boolean-expressions
    return stockNumbers && stockNumbers.length > 0
        ? `?stocknumbers=${stockNumbers.join('&stocknumbers=')}&${getReturnUrlParam()}`
        : `?${getReturnUrlParam()}`;
}

export function stockNumbersToProfileQueryString(stockNumbers: number[]): string {
    // tslint:disable-next-line: strict-boolean-expressions
    return stockNumbers && stockNumbers.length > 0 ? `?stocknumbers=${stockNumbers.join('&stocknumbers=')}` : '';
}

function getReturnUrlParam(stockNumber?: string): string {
    const url = stockNumber ? `${window.location.href}${getFifthCarUrl(stockNumber)}` : window.location.href;
    const encoded = encodeURIComponent(url);
    return `?returnUrl=${encoded}`;
}

function getFifthCarUrl(stockNumber: string): string {
    const url = window.location.href;
    if (url.indexOf('cars') > -1) {
        return url.indexOf('?') > -1 ? `&savedCar=${stockNumber}` : `?savedCar=${stockNumber}`;
    }
    if (url.indexOf('car/') > -1 || url.indexOf('beta') > -1) {
        return url.indexOf('?') > -1 ? '&favorite=true#sign=1' : '?favorite=true#sign=1';
    }
    return '';
}

export const registerUrl = (stockNumber?: string): string => {
    const qs = getReturnUrlParam(stockNumber);
    return `${constants.links.register.href}${qs}`;
};

export const signInUrl = (stockNumber?: string): string => {
    const qs = getReturnUrlParam(stockNumber);
    return `${constants.links.signIn.href}${qs}`;
};
