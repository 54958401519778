export const WATCHLIST_LOCAL_STORAGE = 'parkingLot';
export const WATCHLIST_OVERVIEW_WAS_DISPLAYED_LOCAL_STORAGE = 'parkingLotOverviewWasDisplayed';
export const WATCHLIST_SCREENER_WAS_DISPLAYED_LOCAL_STORAGE = 'parkingLotScreenerWasDisplayed';
export const WATCHLIST_REMINDER_WAS_DISPLAYED_LOCAL_STORAGE = 'parkingLotReminderWasDisplayed';
export const WATCHLIST_AWARENESS_WAS_DISPLAYED_LOCAL_STORAGE = 'parkingLotAwarenessCount';
export const FAVORITE_EVENT_NAMES = {
    ADD_FAVORITE: 'kmxAddSavedCar',
    GET_FAVORITES: 'kmxGetSavedCars',
    REMOVE_FAVORITE: 'kmxRemoveSavedCar',
    SAVE_FAVORITES: 'kmxSaveAuthenticatedUserSavedCars',
};
export const CHAT_EVENT_NAMES = {
    FAB: 'kmxChatFab',
    FOOTER: 'kmxChatFooter',
    HEADER: 'kmxChatHeader',
};
export const WATCHLIST_EVENT_NAMES = {
    ADD_VEHICLE: 'watchListVehicleAdded',
    REMOVE_VEHICLE: 'watchListVehicleRemoved',
    WATCHLIST_ENABLED: 'parkingLotCampaign',
};
export const links = {
    postAuthRedirect: {
        name: 'KmxPostAuthRedirectUrl',
        url: '/watchlist-conversion',
    },
    register: {
        href: '/mycarmax/register',
        text: 'Register',
    },
    signIn: {
        href: '/mycarmax/sign-in',
        text: 'Sign In',
    },
};
