// tslint:disable-next-line: no-submodule-imports
import { KMXDialog } from '@kmx/legos-dialog/index';
import carChat from '../static/max_favorites_car_chat_bubble.svg';
import carHeart from '../static/max_favorites_car_heart.svg';
import satelliteRadioReady from '../static/max_favorites_satellite_radio_ready.svg';
import savedSearches from '../static/max_favorites_saved_searches.svg';
import { registerUrl, signInUrl } from '../utils/watchlistHelper';

export interface ISaveLimitDialogue {
    render(stockNumber?: string): void;
}

export default class SaveLimitDialogue implements ISaveLimitDialogue {
    private dialog: any;
    public render(stockNumber?: string): void {
        const html = `<aside id="watchlist-save-limit"
                class="kmx-dialog watchlist-save-limit"
                role="alertdialog"
                aria-describedby="watchlist-save-limit-description"
                aria-labelledby="save-limit-title">
                <div class="mdc-dialog__surface" tabindex="-1" id="save-limit-dialog-container" aria-labelledby="save-limit-title">
                    <header class="mdc-dialog__header">
                        <h2 class="mdc-dialog__header__title" id="save-limit-title">Get an account and go unlimited</h2>
                        <button id="btn-watchlist-save-limit-close" class="button-dialog-close" aria-label="Close dialog">
                            <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false" role="presentation" width="24" height="24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                        </button> 
                    </header>
                    <section id="watchlist-save-limit-description" class="mdc-dialog__body">
                        <div class="watchlist-save-limit-image">
                        <div class="ie-gridadjust">${carHeart}<div class="image-desc">Save unlimited cars</div></div>
                        <div class="ie-gridadjust">${satelliteRadioReady}<div class="image-desc">Access on any device</div></div>
                        <div class="ie-gridadjust">${carChat}<div class="image-desc">Get inventory alerts</div></div>
                        <div class="ie-gridadjust">${savedSearches}<div class="image-desc">Save searches</div></div>
                    </div>
                    <div>
                        <a class="watchlist-button create-account-button kmx-button kmx-button--primary pl-register" href="${registerUrl(stockNumber)}">Create account</a>
                    </div>
                    <div class="">
                        <button type="button" class="pl-may-be-later">Maybe later</button>
                        </div>
                        <p>Already have an account? <a class="pl-signin" href="${signInUrl(stockNumber)}">Sign In</a></p>
                    </section>
                </div>
                <div class="mdc-dialog__backdrop"></div>
            </aside>`;

        const saveLimitId = '#watchlist-save-limit';
        const bodyEl = document.getElementsByTagName('body')[0];

        let dialogElement = document.querySelector(saveLimitId);
        if (!dialogElement) {
            bodyEl.insertAdjacentHTML('beforeend', html);
            dialogElement = document.querySelector(saveLimitId);
        } else {
            const registerLink = document.querySelector('.watchlist-save-limit a.pl-register') as HTMLLinkElement;
            registerLink.href = registerUrl(stockNumber);
            const signInLink = document.querySelector('.watchlist-save-limit a.pl-signin') as HTMLLinkElement;
            signInLink.href = signInUrl(stockNumber);
        }

        const button = document.querySelector('.pl-may-be-later') as HTMLElement;
        if (button !== null) {
            button.onclick = () => {
                closeDialog();
            };
        }

        const closeButton = document.getElementById('btn-watchlist-save-limit-close') as HTMLElement;
        if (closeButton !== null) {
            closeButton.onclick = () => {
                closeDialog();
            };
        }

        const closeDialog = () => {
            if (this.dialog && this.dialog !== null) {
                this.dialog.close();
            }
        };

        const dialog = new KMXDialog(dialogElement);
        this.dialog = dialog;
        dialog.open();

        const container = document.getElementById('save-limit-dialog-container');
        if (container) {
            container.focus();
        }

    }
}
