import { WATCHLIST_EVENT_NAMES } from './constants';

const addNotificationContent = `<div id="wl-snackbar-desc"><svg viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
<path d="M0 0h24v24H0z" fill="none"></path>
<path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
</svg> Added to your favorites</div>`;
const removeNotificationContent = '<div id="wl-snackbar-desc">Removed from your favorites</div>';
let isOpen = false;
let closeTimeout: ReturnType<typeof setTimeout>;
export const showNotification = (event: CustomEvent) => {
    const snackbar = document.getElementById('parking-lot-snackbar');

    if (snackbar) {
        switch (event.type) {
            case WATCHLIST_EVENT_NAMES.REMOVE_VEHICLE:
                snackbar.innerHTML = removeNotificationContent;
                break;
            case WATCHLIST_EVENT_NAMES.ADD_VEHICLE:
                snackbar.innerHTML = addNotificationContent;
                break;
            default:
                snackbar.innerHTML = event.detail;
                break;
        }
        if (!isOpen) {
            snackbar.focus();
            snackbar.className = 'show';
            snackbar.setAttribute('aria-hidden', 'false');
            isOpen = true;
        }
        if (closeTimeout) {
            clearTimeout(closeTimeout);
        }
        // After 3 seconds, remove the show class from DIV - actually remove after 2.9 so the fade out animation isn't weird
        closeTimeout = setTimeout(() => {
            if (!isOpen) return;
            snackbar.className = snackbar.className.replace('show', '');
            isOpen = false;
        }, 2900);
    }
};
export const loadSnacks = () => {
    const snackDiv = document.getElementById('parking-lot-snackbar');
    if (snackDiv === null) {
        const main = document.getElementsByTagName('body');
        const snackbar = document.createElement('div');
        snackbar.setAttribute('class', 'hidden');
        snackbar.setAttribute('id', 'parking-lot-snackbar');
        snackbar.setAttribute('role', 'dialog');
        snackbar.setAttribute('aria-describedby', 'wl-snackbar-desc');
        if (main !== undefined && main.length > 0) main[0].appendChild(snackbar);
    }
};
