import { addSavedVehicle, getSavedVehicles, postClickEvent, removeSavedVehicle } from './api/savedVehicle';
import OverviewDialogue from './components/OverviewDialogue';
import SaveLimitDialogue from './components/SaveLimitDialogue';
import { FAVORITE_EVENT_NAMES, WATCHLIST_EVENT_NAMES } from './constants';
import { showNotification } from './notification';
import { getUserId, isAuthenticated } from './utils/cookieHelper';
import {
    addVehicle,
    getAuthRedirectUrl,
    getStockNumbers,
    overViewWasDisplayed,
    removeAuthRedirectUrl,
    removeVehicle,
    setAuthRedirectUrl,
    setOverViewWasDisplayed
} from './utils/watchlistHelper';
// tslint:disable: no-unused-expression
let watchlistEnabled = true;
export const init = () => {
    window.addEventListener(FAVORITE_EVENT_NAMES.ADD_FAVORITE, event => {
        const customEvent = event as CustomEvent;
        const result = addFavorite(customEvent.detail);
        customEvent.detail.callback(result) as CustomEvent;
    });
    window.addEventListener(FAVORITE_EVENT_NAMES.REMOVE_FAVORITE, event => {
        const customEvent = event as CustomEvent;
        const result = removeFavorite(customEvent.detail);
        customEvent.detail.callback(result) as CustomEvent;
    });
    window.addEventListener(FAVORITE_EVENT_NAMES.GET_FAVORITES, event => {
        const customEvent = event as CustomEvent;
        const result = getFavorites();
        customEvent.detail.callback(result) as CustomEvent;
    });
    window.addEventListener(WATCHLIST_EVENT_NAMES.WATCHLIST_ENABLED, () => {
        watchlistEnabled = true;
    });
};

export const addFavorite = async (eventDetail: any): Promise<boolean> => {
    const addEvent = new CustomEvent(WATCHLIST_EVENT_NAMES.ADD_VEHICLE);
    if (!isAuthenticated() && watchlistEnabled) {
        try {
            const count = getStockNumbers().length;
            if (count >= 4) {
                renderLimitReachedDialogue(eventDetail.stockNumber);
                return new Promise(resolve => {
                    resolve(false);
                });
            }
            addVehicle(eventDetail.stockNumber as number);
            postClickEvent(eventDetail.stockNumber, true);
            const authUrl = getAuthRedirectUrl();
            if (!authUrl || authUrl === '') {
                setAuthRedirectUrl();
            }
            if (count === 0 && !overViewWasDisplayed()) {
                setOverViewWasDisplayed();
                renderOverviewDialogue(eventDetail.stockNumber);
            } else if (!eventDetail.suppressNotifications) {
                showNotification(addEvent);
            }
            return new Promise(resolve => {
                resolve(true);
            });
        } catch {
            return new Promise(resolve => {
                resolve(false);
            });
        }
    }
    if (!watchlistEnabled && !isAuthenticated()) {
        return new Promise(resolve => {
            resolve(false);
        });
    }
    return new Promise((resolve, reject) => {
        const userId = getUserId();
        if (userId === '') reject(false);
        try {
            addSavedVehicle(userId, eventDetail.stockNumber, (response: any) => {
                if (!eventDetail.suppressNotifications) {
                    showNotification(addEvent);
                }
                resolve(true);
            });
        } catch (e) {
            console.error(`Error adding saved vehicle: ${e}`);
            reject(false);
        }
    });
};
const removeFavorite = async (eventDetail: any): Promise<boolean> => {
    const removeEvent = new CustomEvent(WATCHLIST_EVENT_NAMES.REMOVE_VEHICLE, {
        detail: { stockNumber: eventDetail.stockNumber },
    });
    if (!isAuthenticated() && watchlistEnabled) {
        try {
            removeVehicle(eventDetail.stockNumber as number);
            postClickEvent(eventDetail.stockNumber, false);
            if (getStockNumbers().length === 0) {
                removeAuthRedirectUrl();
            }
            if (!eventDetail.suppressNotifications) {
                showNotification(removeEvent);
            }
            return new Promise(resolve => {
                resolve(true);
            });
        } catch {
            return new Promise(resolve => {
                resolve(false);
            });
        }
    }

    if (!watchlistEnabled && !isAuthenticated()) {
        return new Promise(resolve => {
            resolve(false);
        });
    }

    return new Promise((resolve, reject) => {
        const userId = getUserId();
        if (userId === '') reject([]);
        try {
            removeSavedVehicle(userId, eventDetail.stockNumber, (response: any) => {
                if (!eventDetail.suppressNotifications) {
                    showNotification(removeEvent);
                }
                resolve(true);
            });
        } catch (e) {
            console.error(`Error removing saved vehicle: ${e}`);
            reject(false);
        }
    });
};

const getFavorites = async (): Promise<any> => {
    if (!isAuthenticated() && watchlistEnabled) {
        const stockNums = getStockNumbers();
        return new Promise<[]>(resolve => {
            resolve(stockNums);
        });
    }
    if (!watchlistEnabled && !isAuthenticated()) {
        return new Promise<[]>(resolve => {
            resolve([]);
        });
    }
    return new Promise<[]>((resolve, reject) => {
        try {
            const userId = getUserId();
            if (userId === '') reject([]);
            getSavedVehicles(getUserId(), (response: any) => {
                if (response.items && response.items.length > 0) {
                    const stockNums = response.items.map((x: any) => x.stockNumber);
                    resolve(stockNums);
                }

                resolve([]);
            });
        } catch (e) {
            console.error(`Error getting saved vehicles: ${e}`);
            reject([]);
        }
    });
};

const renderOverviewDialogue = (stockNumber? : string) => {
    new OverviewDialogue().render(stockNumber);
};

const renderLimitReachedDialogue = (stockNumber?: string) => {
    new SaveLimitDialogue().render(stockNumber);
};

// tslint:enable: no-unused-expression
