interface IEnv {
    prod: boolean;
    qa: boolean;
    dev: boolean;
}
type Env = IEnv;

export const get = (hostname: string = window.location.hostname): Env => {
    return {
        dev: /(local|dev)/.test(hostname),
        prod: !/(qa|local|dev)/.test(hostname),
        qa: /(qa)/.test(hostname),
    };
};
